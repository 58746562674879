<template>
    <!-- Loading overlay to indicate progress or loading state -->
    <LoadingOverlay :message="message" />

    <!-- Selection box for rectangle annotations in the viewer -->
    <div
        id="selectorDiv"
        v-on:mouseup="onmouseup"
        v-on:mousemove="onmousemove"
        style="border: 2px solid #000; position: absolute; z-index: 5; visibility: hidden;"
    ></div>

    <div class="zoom">
        <!-- Button to navigate to home -->
        <img
            src="../../assets/button_home.svg"
            alt=""
            @click="prelinkToHome()"
            height="50"
        />
        <!-- Button to refresh the document -->
        <img
            src="../../assets/button_obnovenie.svg"
            alt=""
            v-on:click="renew"
            height="50"
            :title="$t('pdfviewer.docRefresh')"
        />
        <!-- Button to add highlight annotations -->
        <img
            src="../../assets/button_anotovanie.svg"
            alt=""
            v-on:click="addHighlightAnnotation"
            height="50"
            :title="$t('pdfviewer.textAnotation')"
        />
        <!-- Button to add rectangle annotations -->
        <img
            src="../../assets/button_volna_an.svg"
            alt=""
            v-on:click="addSquareAnnotation"
            height="50"
            :title="$t('pdfviewer.freeAnotation')"
            :class="{ activeButton: doSquare }"
        />
        <!-- Button to save the current annotations as template -->
        <button
            @click="showTemplateNameModal"
            type="button"
            class="downloadDocButtonAlt"
            id="saveTemplate"
        >
            {{ $t('formview.SaveTemplate') }}
        </button>

        <!-- Modal for template name input -->
        <teleport to="body">
            <div class="modalNameTemplates" v-show="templateNameModalOpen" @click="handleClick">
                <div class="modalViewNameTemplates">
                    <templateNameModal
                        v-show="templateNameModalOpen"
                        @close="closeTemplateNameModal"
                        :templateNames="templateNames"
                        @save-template="saveTemplate"
                    />
                </div>
            </div>
        </teleport>

        <!-- Button to download the document -->
        <button
            class="downloadDocButtonAlt"
            v-on:click="download"
            id="download"
            :title="$t('pdfviewer.downloadDoc')"
        >
            {{ $t('pdfviewer.downloadDoc') }}
        </button>
        <!-- Button to send the document for anonymization -->
        <button
            class="sendDocButtonAlt"
            v-on:click="pushPdf"
            id="pushPdf"
            :title="$t('pdfviewer.sendForAnonymization')"
        >
            {{ $t('pdfviewer.sendForAnonymization') }}
        </button>
    </div>

    <div class="viewContainer">
        <!-- Annotation Menu Toggle -->
        <div v-show="!toggleAnnotMenu" class="toggleAnnotDiv">
            <img
                src="../../assets/double-chevron-right.svg"
                class="toggleAnnotMenu"
                @click="toggleAnnotMenu = !toggleAnnotMenu"
            />
        </div>
        <!-- Annotation Sidebar LEFT -->
        <div v-show="toggleAnnotMenu" class="toggleAnnotDiv">
            <div class="leftSideBar">
                <div class="sideBarContainer leftContainer">
                    <div id="leftSideBarContainer" style="height: 100%;">
                        <div class="itemContainer">
                            <!-- Section for annotation actions -->
                            <h3>{{ $t('pdfviewer.anotInText') }}</h3>
                            <div class="itemContainerImages">
                                <!-- Delete all highlights button -->
                                <img
                                    src="../../assets/button_delete_highlights.svg"
                                    alt=""
                                    v-on:click="deleteAllHighlightedAnnots"
                                    :title="$t('pdfviewer.delAllHighlights')"
                                />
                                <!-- Delete all annotations button -->
                                <img
                                    src="../../assets/button_delete_anots.svg"
                                    alt=""
                                    v-on:click="deleteAnnotations"
                                    :title="$t('pdfviewer.delAllAnotations')"
                                />
                            </div>
                        </div>

                        <!-- List of annotations by page -->
                        <div id="annotationContainer">
                            <div
                                id="annotations"
                                v-for="(page, index) in uniqueAnnotations"
                                :key="index"
                            >
                                <div v-for="(annot, index2) in page" :key="index2">
                                    <!-- Annotation item -->
                                    <div
                                        v-on:click="highlightAnnotation(annot.contents)"
                                        :ref="`annot_page${index}_index${index2}`"
                                        :class="[customColorClass(annot.author), 'annotation']"
                                        v-if="
                                            checkAnnotType(annot.type) &&
                                            controlDeletedObjects(annot.id, annot.page)
                                        "
                                    >
                                        <div class="textAnnotationSection">
                                            {{ annotationTypeFormatter(annot.type) }}
                                            <label class="annotationPage">
                                                {{ annotationPageFormatter(annot.page) }}
                                            </label>
                                        </div>
                                        <!-- Delete annotation button -->
                                        <div class="deleteAnnotationSection">
                                            <label
                                                @click="deleteAnnotation(annot.contents)"
                                                class="deleteAnnotation"
                                            >
                                                <img src="../../assets/NOT_icon.svg" class="notIcon" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Close annotation sidebar -->
            <img
                src="../../assets/double-chevron-left.svg"
                class="toggleAnnotMenu"
                @click="toggleAnnotMenu = !toggleAnnotMenu"
            />
        </div>

        <!-- PDF Viewer Section -->
        <div id="view">
            <div
                id="viewerContainer"
                v-on:mousedown="onmousedown"
                v-on:mouseup="onmouseup"
                v-on:mousemove="onmousemove"
                style="padding-top: 30px; padding-bottom: 30px;"
            >
                <div id="viewer" class="pdfViewer"></div>
            </div>
        </div>

        <!-- Close suggestion sidebar -->
        <div v-show="!toggleSuggestMenu" class="toggleSuggestDiv">
            <img
                src="../../assets/double-chevron-left.svg"
                class="toggleSuggesterMenu"
                @click="toggleSuggestMenu = !toggleSuggestMenu"
            />
        </div>
        <!-- Suggestion Sidebar RIGHT -->
        <div v-show="toggleSuggestMenu" class="toggleSuggestDiv">
            <img
                src="../../assets/double-chevron-right.svg"
                class="toggleSuggesterMenu"
                @click="toggleSuggestMenu = !toggleSuggestMenu"
            />
            <div class="rightSideBar">
                <div class="sideBarContainer">
                    <div class="itemContainer">
                        <!-- Header for suggested annotations -->
                        <h3>{{ $t('pdfviewer.textrecomendation') }}</h3>
                    </div>

                    <!-- List of suggested annotations -->
                    <div id="suggestedAnnots">
                        <div class="selectAll">
                            <span class="annotation">
                                <!-- Checkbox to select/deselect all suggestions -->
                                <input
                                    class="inputCheckAll"
                                    type="checkbox"
                                    v-model="checkAll"
                                    id="idCheckAll"
                                    @click="suggestedCheckboxState"
                                />
                                <label class="labelCheckAll">
                                    {{
                                        checkAll
                                            ? $t('pdfviewer.cancelAnnotation')
                                            : $t('pdfviewer.selectAll')
                                    }}
                                </label>
                            </span>
                        </div>
                        <span
                            class="annotation"
                            v-for="(item, key) in suggestedAnnotations"
                            :key="key"
                        >
                            <!-- Checkbox for individual suggestion -->
                            <input
                                class="suggestedInputField"
                                :checked="isCheckedAnnot(key)"
                                type="checkbox"
                                @change="markSuggested(item.coordinates, item.pageNumber, key)"
                                :name="item.category + ' - ' + item.phrase"
                                :value="item.phrase"
                                :id="key"
                            />
                            <!-- Highlight and info for suggested annotation -->
                            <div
                                class="textAnnotationSection textRecommendSection"
                                v-on:click="highlightSuggested(item.coordinates, item.pageNumber, key)"
                            >
                                <label>{{ setLabelForRecommendCategory(item.category) }}</label>
                                <label><b>{{ item.phrase }}</b></label>
                                <label class="annotationPage">
                                    {{ $t('pdfviewer.page') + ' ' + String(item.pageNumber + 1) }}
                                </label>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import '../../../node_modules/pdfjs-dist/web/pdf_viewer.css'
import { AnnotationFactory } from '../../../public/pdfAnnotate.js'
import { getDocument, GlobalWorkerOptions, renderTextLayer } from 'pdfjs-dist'
import { useCookies } from 'vue3-cookies'
import EnvProvider from 'jvjr-docker-env';
import LoadingOverlay from "../LoadingOverlay.vue"
import templateNameModal from "../../components/templateNameModal.vue";

export default {
    name: "pdfviewer",
    components: { LoadingOverlay, templateNameModal },
    props: ["documentUrl", "fileId", "minex"],
    setup() {
        const { cookies } = useCookies();
        return { cookies };
    },
    data: function () {
        return {
            templateNameModalOpen: false,
            templateNames: [],
            template: [],
            color: "#BFC547",
            filename: '',
            annotations: [],
            pdfDocument: undefined,
            pdfFactory: undefined,
            pdfViewer: undefined,
            pdfData: undefined,
            pdfPages: 0,
            pdfPagesData: [],
            pagesPosition: [],
            marginUnderPage: 10,
            lastCoordPage: 0,
            pageNumberCustomRect: 0,
            doSquare: false,
            coordinates: [],
            squarePages: [],
            htmlCoords: [],
            htmlcoordinates: [],
            textSelAnnots: [],
            suggestedAnnotations: {},
            checkedAnnots: {},
            annotGroups: [],
            markedAnnots: [],
            suggestAnnotIndexOffset: 10000,
            checkAll: false,
            toggleAnnotMenu: false,
            toggleSuggestMenu: false,
            maxWidth: 850,
            selectorDiv: undefined,
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 0,
            message: 'Loading...',
            isPushingPdf: false,
        }
    },

    created() {
        window.addEventListener('beforeunload', this.deleteBeforeUnload);
    },

    destroyed() {
        window.removeEventListener('beforeunload', this.deleteBeforeUnload);
    },

    mounted() {
        this.load();
        this.renderPdf();
        window.addEventListener('resize', this.handlePixelRatioChange);
    },

    beforeUnmount() {
        this.deleteBeforeUnload();
        window.removeEventListener('resize', this.handlePixelRatioChange);
    },

    computed: {
        uniqueAnnotations() {
            const uniqueContents = new Set();

            var annots = this.annotations.map((page) =>
                page.filter((annot) => {
                    if (typeof(annot.contents) === 'undefined') {
                        return false;
                    }
                    if (!uniqueContents.has(annot.contents)) {
                        uniqueContents.add(annot.contents);
                        return true;
                    }
                    return false;
                })
            );

            return annots;
        },
    },

    methods: {
        handlePixelRatioChange() {
            const scale = window.devicePixelRatio
            this.setPagesPosition();
        },
        showTemplateNameModal() {
			this.getTemplates();
			this.templateNameModalOpen = true;
		},
		closeTemplateNameModal() {
			this.templateNameModalOpen = false;
		},
        getTemplates(){
            this.axios.get(EnvProvider.value("API_URL") + this.$root.API_TEMPLATE, this.templates)
                .then(response => {
                    if (response.status === 200) {
                        let data = JSON.parse(response.data);
						if (Object.keys(data).length === 0) {
							this.templates = []
						}
						else {
							this.templates = data.templates
						}
						this.templateNames = this.templates.map(template => template.name);
                        this.templates = [];
                    }
                }).catch(error => {
                    console.log(error);
                });
        },
        saveTemplate(templateName) {
            this.template = {name: templateName, values: {}};
            var count = 0
            this.pdfFactory.getAnnotations().then(
                annotations => {
                    annotations = this.annotations
                    var keys = Object.values(annotations);
                    for (var page = 0; page < keys.length; page++) {
                        for (let annot = 0; annot < keys[page].length; annot++) {
                            const element = keys[page][annot];
                            var mappedData = {
                                "rect": element.rect,
                                "color": element.color,
                                "type": element.type,
                                "opacity": element.opacity,
                                "type_encoded": element.type_encoded,
                                "author": element.author,
                                "contents": element.contents,
                                "page": element.page,
                                "quadPoints": element.quadPoints
                            };
                            this.template.values[count] = mappedData;
                            count++;
                        }
                    }

                    this.axios.post(EnvProvider.value('API_URL') + this.$root.API_TEMPLATE, this.template, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(response => {
                        if (response.status === 200) {
                            console.log("Templates sent");
                        }
                    }).catch(error => {
                        console.log(error);
                    });
                }
            );
        },
        deleteBeforeUnload() {
            if (this.isPushingPdf) {
                return;
            }
            const fileId = this.fileId;
            this.axios.get(EnvProvider.value('API_URL') +'/delete/document/'+ fileId)
                .then(response => {
                    console.log('ano');
                })
                .catch(error => {
                    console.error('Failed to send file id', error);
                });
        },
        prelinkToHome() {
            this.$root.clearValues()
            this.$router.push({
                name: 'HomeView',
            })
        },
        showOverlay(message) {
            this.message = message;
            this.$store.state.showOverlay = true;
        },
        closeOverlay() {
            this.$store.state.showOverlay = false;
            this.message = this.$t("LoadingMessages.pdfLoading");
        },
        reCalc() { 
            // This will restyle the div
            let x3 = Math.min(this.x1, this.x2); //Smaller X
            let x4 = Math.max(this.x1, this.x2); //Larger X
            let y3 = Math.min(this.y1, this.y2); //Smaller Y
            let y4 = Math.max(this.y1, this.y2); //Larger Y
            this.selectorDiv.style.left = x3 + 'px';
            this.selectorDiv.style.top = y3 + 'px';
            this.selectorDiv.style.width = (x4 - x3) + 'px';
            this.selectorDiv.style.height = (y4 - y3) + 'px';
        },
        onmousedown(e) {
            if (this.doSquare) {
                // Unhide the rectangle annot div
                this.selectorDiv.style.visibility = "visible"; 
                let html = document.getElementsByTagName('html')[0]
                let top = html.scrollTop
                let left = html.scrollLeft
                let x = (e.clientX + left)
                let y = (e.clientY + top)
                
                this.x1 = x
                this.y1 = y
                this.x2 = x
                this.y2 = y
                this.reCalc();
            }

            this.processCoordinates(e)

            // Highlight annot if clicked inside annot
            this.highlightClickedAnnot(e)
        },
        onmousemove(e) {
            if (this.doSquare && this.htmlcoordinates.length == 2) {
                let html = document.getElementsByTagName('html')[0]
                let top = html.scrollTop
                let left = html.scrollLeft
                let x = (e.clientX + left)
                let y = (e.clientY + top)
                
                this.x2 = x;
                this.y2 = y;
                this.reCalc();
            }
        },
        onmouseup(e) {
            if (this.doSquare && this.htmlcoordinates.length == 2) {
                //Hide the div
                this.selectorDiv.style.visibility = "hidden";

                // Save second point
                this.cleanSelectArea()
                this.processCoordinates(e)
            }
        },
        addMarkedAnnotation(rectangle, page, id){
            let annot = this.getMarkedAnnotation(id);

            if (annot) {
                annot.annots.push({
                    page: page,
                    rectangle: rectangle
                });
                this.markAnnot(rectangle, page,id);
            }
            else {
                let marked = {
                    id: id,
                    annots: []
                };
                marked.annots.push({
                    page: page,
                    rectangle: rectangle
                });
                this.markAnnot(rectangle, page,id);
                this.markedAnnots.push(marked);
            }
        },
        getMarkedAnnotation(id){
            for (var annot in this.markedAnnots){
                if (this.markedAnnots[annot].id == id) {
                    return this.markedAnnots[annot];
                }
            }
            return null;
        },
        removeMarkedAnnotation(id){
            this.markedAnnots = this.markedAnnots.filter(function(v) { return v.id != id })
            this.unmarkAnnot(id);
        },
        highlightedItemClass(author) {
            // Add highlighted class
            switch (author) {
                case 'anonymize-text':
                    return 'annot-manual-item-highlight';
                case 'anonymize-square':
                    return 'annot-manual-item-highlight';
                case 'anonymize-user-input':
                    return 'annot-user-item-highlight';
                case 'anonymize-minex':
                    return 'annot-minex-item-highlight';
                default:
                    return '';
            }
        },
        customColorClass(author) {
            // Check the author and return the corresponding color class
            switch (author) {
                case 'anonymize-text':
                    return 'annot-manual-bgcolor';
                case 'anonymize-square':
                    return 'annot-manual-bgcolor';
                case 'anonymize-user-input':
                    return 'annot-user-input-bgcolor';
                case 'anonymize-minex':
                    return 'annot-minex-bgcolor';
                default:
                    return '';
            }
        },
        filterRectangles(rectangles) {
            // Settings for overlap filter
            // 0.3 means that if more than 30 % is NOT overlapped in x or y axis, we do not merge
            // or that we merge if 70% or more is overlapped in x or y axis
            const horizontalOverlap = 1.1
            const verticalOverlap = 0.7

            // Define a small y tolerance to treat symbols on the same line
            // Adjust based on symbol size variance
            const yTolerance = 5;  

            // Convert DOMRectList to a regular array
            let rectArray = Array.from(rectangles).filter(rect => rect.width !== 0 && rect.height !== 0);

            // Sort rectangles by their top-left coordinates
            rectArray.sort((a, b) => Math.abs(a.y - b.y) <= yTolerance ? a.x - b.x : a.y - b.y);

            // Initialize result array with the first rectangle
            const mergedRectangles = [];

            for (let i = 0; i < rectArray.length; i++) {
                const rect = rectArray[i];

                // Convert coordinates to page-relative if needed 
                let viewRect = this.calculateCoordinatesForHtmlFromRect(rect);
                this.calculateCoordinatesForPageFromPoint(viewRect[0], viewRect[1]);

                let pageHeight = parseInt(this.pagesPosition[this.lastCoordPage].height);
                let pageWidth = parseInt(this.pagesPosition[this.lastCoordPage].width);
                let pageX = this.pagesPosition[this.lastCoordPage].x;
                let pageY = this.pagesPosition[this.lastCoordPage].y;

                // Exclude rectangles that are out of the page bounds or too large
                if (
                    rect.height >= pageHeight || rect.width >= pageWidth ||
                    viewRect[1] < pageY || viewRect[3] >= (pageY + pageHeight) ||
                    rect.x < pageX || rect.x >= (pageX + pageWidth)
                ) {
                    console.warn("Excluding out of bounds rect");
                    continue;
                }

                // Merging logic with overlap threshold
                if (mergedRectangles.length > 0) {
                    const previous = mergedRectangles[mergedRectangles.length - 1];

                    if (rect.x <= previous.x + (previous.width * horizontalOverlap)) {
                        // Update previous rectangle if overlapping vertically
                        if (rect.y <= previous.y + (previous.height * verticalOverlap)) {
                            previous.width = Math.max(
                                previous.x + previous.width,
                                rect.x + rect.width
                            ) - previous.x;
                            previous.height = Math.max(
                                previous.y + previous.height,
                                rect.y + rect.height
                            ) - previous.y;
                        } else {
                            mergedRectangles.push(rect);
                        }
                    } else {
                        mergedRectangles.push(rect);
                    }
                } else {
                    mergedRectangles.push(rect);
                }
            }
            
            return mergedRectangles;
        },
        getSelectionBoxes() {
            // Get the current selection object
            const selection = window.getSelection();
            if (!selection.rangeCount) return [];

            const allRects = [];

            // Loop through each text layer
            document.querySelectorAll('.textLayer').forEach(textLayer => {
                const layerRect = textLayer.getBoundingClientRect();

                // Get rectangles within this specific text layer
                const rangeRects = Array.from(selection.getRangeAt(0).getClientRects()).filter(rect => {
                    return (
                        rect.top >= layerRect.top && rect.bottom <= layerRect.bottom &&
                        rect.left >= layerRect.left && rect.right <= layerRect.right
                    );
                });

                // Add to results after filtering non-text rectangles
                allRects.push(...this.filterRectangles(rangeRects));
            });

            return allRects;
        },
        cleanSelectArea() {
            this.selectorDiv.style.left = '0px';
            this.selectorDiv.style.top = '0px';
            this.selectorDiv.style.width = '0px';
            this.selectorDiv.style.height = '0px';
            this.x1 = 0
            this.x2 = 0
            this.y1 = 0
            this.y2 = 0
        },
        isCheckedAnnot(key) {
            return (typeof this.checkedAnnots[key] == 'object')
        },
        suggestedCheckboxState() {
            if (this.checkAll) {
                this.suggestedAnnotations.forEach((value, index, array) => {
                    if (this.checkedAnnots[index] != undefined) {
                        let id = this.checkedAnnots[index].id
                        delete this.checkedAnnots[index]
                        this.markedAnnots = this.markedAnnots.filter(function(v) { return v.id != id })
                        this.deleteAnnotation(id)
                    }
                })
            }
            else {
                this.selectAllSuggestedAnnot()
            }
        },
        parseHexToRGB() {
            let s = this.color.slice(1, 7)
            let aRgbHex = s.match(/.{1,2}/g)
            let aRgb = [
                parseInt(aRgbHex[0], 16),
                parseInt(aRgbHex[1], 16),
                parseInt(aRgbHex[2], 16)
            ]
            return aRgb;
        },
        setPagesPosition() {
            this.pagesPosition = []
            const nPages = this.pdfPages;

            for (let i = 1; i <= nPages; i++){
                let pg = document.getElementById("page" + i).parentElement;
                var rect = pg.getBoundingClientRect();
                let pgPos = {};

                if (i === 1){
                    pgPos = {
                        y: 0,
                        x: rect.x,
                        width: rect.width,
                        height: rect.height
                    }
                } else {
                    pgPos = {
                        y: this.pagesPosition[i-2].y + (this.pagesPosition[i-2].height + this.marginUnderPage),
                        x: rect.x,
                        width: rect.width,
                        height: rect.height
                    }
                }
                this.pagesPosition.push(pgPos);
            };
        },
        load() {
            try {
                // Create a URL object
                const url = new URL(this.documentUrl, "http://example.com");

                // Get the value of the 'filename' parameter
                this.filename = url.searchParams.get("filename");

                // Load annotations
                this.suggestedAnnotations = JSON.parse(this.minex)

                // Use modified pdf worker that does not render annotations
                GlobalWorkerOptions.workerSrc = '/pdf.worker.js'

                // Load DOM elements
                this.pdfContainer = document.getElementById('viewer')
                this.selectorDiv = document.getElementById('selectorDiv')
            } catch (e) {
                console.error(e)
                this.$router.push({
                    name: 'HomeView'
                });
            }
        },
        setXCSRFToken() {
            if (this.$store.state.loggedUser === 'testuser' && EnvProvider.value('ENV') === 'DEV') {
                return this.$root.TOKEN_CSRF
            } else {
                return this.cookies.get('csrfToken')
            }
        },
        async renderPdf(scale = 1.7) {
            // Render the PDF
            this.showOverlay(this.$t("LoadingMessages.pdfLoading"));

            const loadingTask = getDocument({
                url: EnvProvider.value('API_URL') + this.documentUrl,
                withCredentials: (EnvProvider.value('ENV') === 'DEV'),
                httpHeaders: {
                    "x-csrf-token": this.setXCSRFToken(),
                },
            });

            try {
                // Wait for PDF loading
                const pdf = await loadingTask.promise;

                this.pdfDocument = pdf;
                this.pdfPages = pdf.numPages;

                // Get the first page to calculate the scale
                const firstPage = await pdf.getPage(1);
                const firstViewport = firstPage.getViewport(1.0);
                const maxWidth = this.maxWidth;

                // Calculate the scale to fit maxWidth
                this.calculatedScale = (maxWidth / firstViewport.width);
                this.calculatedScale2 = (maxWidth / firstViewport.width) * scale;

                // Wait for the data needed to create the annotation factory
                const pdfdata = await this.pdfDocument.getData(); // Get PDF data
                this.pdfFactory = new AnnotationFactory(pdfdata); // Initialize the AnnotationFactory
                
                // Clear previous PDF rendering (if any)
                this.pdfContainer.innerHTML = '';
                this.pdfPagesData = [];

                // Array to hold all rendering promises
                let renderPromises = [];

                for (let pageNum = 1; pageNum <= this.pdfPages; pageNum++) {
                    this.showOverlay(this.$t("LoadingMessages.pdfLoading") + " " + pageNum + "/" + this.pdfPages);

                    // Await the page retrieval
                    const pdfPage = await pdf.getPage(pageNum);

                    const viewport = pdfPage.getViewport(this.calculatedScale);
                    const viewport2 = pdfPage.getViewport(this.calculatedScale2);

                    // Store the page data for later use
                    this.pdfPagesData.push({ page: pdfPage, viewport: viewport, viewport2: viewport2});

                    // Create the canvas
                    const canvas = document.createElement('canvas');
                    canvas.id = "page" + pageNum;
                    canvas.className = 'pdf-canvas';

                    // Set the display dimensions
                    canvas.style.width = `${viewport.width}px`;
                    canvas.style.height = `${viewport.height}px`;

                    // Set the internal resolution
                    canvas.width = Math.floor(viewport2.width);
                    canvas.height = Math.floor(viewport2.height);

                    const ctx = canvas.getContext('2d');

                    // Render the PDF page onto the canvas and push the render promise into the array
                    const renderTask = pdfPage.render({
                        canvasContext: ctx,
                        viewport: viewport2,
                    }).promise; // Get the promise of the render task
                    
                    renderPromises.push(renderTask); // Push the promise to the array

                    // Create a div for the text layer
                    const textLayerDiv = document.createElement('div');
                    textLayerDiv.className = 'textLayer';
                    textLayerDiv.id = 'page' + pageNum + 'textLayer';
                    textLayerDiv.style.width = `${viewport.width}px`; // Match canvas size
                    textLayerDiv.style.height = `${viewport.height}px`; // Match canvas size
                    textLayerDiv.style.position = 'absolute'; // Align within parent container
                    textLayerDiv.style.top = '0';
                    textLayerDiv.style.left = '0';

                    // Create a container for both the canvas and text layer
                    const pageContainer = document.createElement('div');
                    pageContainer.className = 'pdf-page-container'; // Use the class instead of inline styles
                    pageContainer.style.position = 'relative';
                    pageContainer.style.width = `${viewport.width}px`; // Match container size with viewport
                    pageContainer.style.height = `${viewport.height}px`; // Match container size with viewport

                    // Add margin between pages, except the last one
                    if (pageNum < this.pdfPages) {
                        pageContainer.style.marginBottom = `${this.marginUnderPage}px`;
                    }

                    // Append canvas and text layer to the page container
                    pageContainer.appendChild(canvas);
                    pageContainer.appendChild(textLayerDiv);
                    this.pdfContainer.appendChild(pageContainer);

                    // Render the text layer
                    renderTextLayer({
                        textContentStream: pdfPage.streamTextContent(),
                        container: textLayerDiv,
                        viewport: viewport,
                        textDivs: [],
                    });
                }

                // Wait for all pages to finish rendering
                await Promise.all(renderPromises); // Ensure all renders complete

                // Wait for the annotation factory to initialize, then set annotations
                if (this.pdfFactory) {
                    this.setAnnotations();  // Ensure annotations are set after factory is ready
                }

                // Set positions for pages (if needed)
                this.setPagesPosition();

            } catch (error) {
                console.error('Error rendering PDF:', error);
            } finally {
                // Close the loading overlay
                this.closeOverlay();
            }
        },
        async renew() {
            // Delete annotations
            this.deleteAnnotations()

            // Renew the annotations
            const pdfdata = await this.pdfDocument.getData(); // Get PDF data
            this.pdfFactory = new AnnotationFactory(pdfdata); // Initialize the AnnotationFactory

            // Load and draw the annotations
            this.setAnnotations();
            this.setPagesPosition();
        },
        markSuggested(coords, page, key) {
            let color = this.parseHexToRGB()
            const scale = this.pdfPagesData[page].viewport.scale
            const scale2 = 1
            var maxY = this.pdfPagesData[page].viewport.height
            maxY = maxY / scale

            var newRect = []
            var newRects = []
            if (this.checkedAnnots[key] == undefined) {
                let id = this.getNewAnnotGroup(true)
                this.addAnnotGroup(id)

                if (typeof (coords.x0) === 'undefined') {
                    coords.forEach(element => {
                        let rect = this.calculateCoordinatesForAnnot([element.x0,
                            element.y0,
                            element.x1,
                            element.y1], page)
                        newRects.push(rect)
                        this.addMarkedAnnotation(rect,page,id)
                        this.pdfFactory.createHighlightAnnotation(
                            page,
                            [(rect[0] / (scale2 * scale)),
                            (maxY - (rect[1] / (scale2 * scale))),
                            (rect[2] / (scale2 * scale)),
                            (maxY - (rect[3] / (scale2 * scale)))],
                            id.toString(),
                            "anonymize-minex",
                            { r : color[0], g : color[1], b : color[2] }
                        )
                    });
                } else {
                    var newRect = this.calculateCoordinatesForAnnot(
                        [coords.x0,
                        coords.y0,
                        coords.x1,
                        coords.y1], page)

                    this.addMarkedAnnotation(newRect, page, id)
                    this.pdfFactory.createHighlightAnnotation(
                        page,
                        [(newRect[0] / (scale2 * scale)),
                        (maxY - (newRect[1] / (scale2 * scale))),
                        (newRect[2] / (scale2 * scale)),
                        (maxY - (newRect[3] / (scale2 * scale)))],
                        id.toString(),
                        "anonymize-minex",
                        { r : color[0], g : color[1], b : color[2] }
                    )
                }
                if (newRect.length != 0) {
                    this.checkedAnnots[key] = { newRect, page, id}
                }
                else if (newRects.length != 0) {
                    this.checkedAnnots[key] = { newRects, page, id }
                }
                this.refreshListOfAnnotations()
            } else {
                let id = this.checkedAnnots[key].id
                delete this.checkedAnnots[key]
                this.markedAnnots = this.markedAnnots.filter(function(v) { return v.id !== id })

                this.deleteAnnotation(id)
            }

            if (Object.keys(this.checkedAnnots).length > 0) {
                this.checkAll = true
            } else if (Object.keys(this.checkedAnnots).length == 0) {
                this.checkAll = false
            }
        },
        selectAllSuggestedAnnot() {
            this.suggestedAnnotations.forEach((value, index, array) => {
                console.log(value, index)
                this.markSuggested(value.coordinates, value.pageNumber, index)
            })
        },
        calculateCoordinatesForAnnot(rect, actualPage) {
            const scale = this.pdfPagesData[actualPage].viewport.scale
            const scale2 = 1
            let rec = rect
            let x_1 = (rec[0] * (scale2 * scale))
            let y_1 = (rec[1] * (scale2 * scale))
            let x_2 = (rec[2] * (scale2 * scale))
            let y_2 = (rec[3] * (scale2 * scale))
            return [x_1, y_1, x_2, y_2]
        },
        annotationTypeFormatter(annotType) {
            if (annotType == "/Highlight")
                return this.$t("pdfviewer.textAnotation")
            else if (annotType == "/FreeText" || annotType == "/Square")
                return this.$t("pdfviewer.freeAnotation")
            else return annotType
        },
        annotationPageFormatter(annotPage) {
            return this.$t("pdfviewer.page") + (annotPage + 1)
        },
        selectionCoordinates(x_1, y_1, x_2, y_2, actualPage) {
            let x_1_y_1 = this.pdfPagesData[actualPage].viewport.convertToPdfPoint(x_1, y_1)
            x_1 = x_1_y_1[0]
            y_1 = x_1_y_1[1]
            let x_2_y_2 = this.pdfPagesData[actualPage].viewport.convertToPdfPoint(x_2, y_2)
            x_2 = x_2_y_2[0]
            y_2 = x_2_y_2[1]
            return [x_1, y_1, x_2, y_2]
        },
        setAnnotations() {
            var annotations = this.pdfFactory.getAnnotations()
            annotations.then(value => {
                for (var i in value) {
                    for (var annotation in value[i]) {
                        if (this.checkAnnotType(value[i][annotation].type) && this.checkAnnotAuthor(value[i][annotation].author) && this.controlDeletedObjects(value[i][annotation].id, value[i][annotation].page)) {
                            let actualPage = value[i][annotation].page
                            let rect = value[i][annotation].rect
                            let groupid = value[i][annotation].contents

                            if (value[i][annotation].author == 'anonymize-user-input') {
                                value[i][annotation].rect = [rect[0],rect[3],rect[2],rect[1]]
                            }

                            this.updateAnnotation(value[i][annotation])

                            // Draw the annotation
                            const htmlCoords = this.calculateCoordinatesFromCustomRect(value[i][annotation].rect, actualPage)
                            this.markAnnot(htmlCoords, actualPage,groupid);
                        }
                    }
                }

                this.annotations = value
                this.loadAnnotGroups();
            })
        },
        updateAnnotation(updatedAnnotation) {
            let selectedColor = this.parseHexToRGB()
            const annotationObjectId = updatedAnnotation.object_id;
            
            // Delete the original annotation
            this.pdfFactory.deleteAnnotation(annotationObjectId)
                .then(value => {
                    // Create a new annotation with updated values
                    if (updatedAnnotation.type === '/Highlight') {
                        this.pdfFactory.createHighlightAnnotation({
                            page: updatedAnnotation.page,
                            rect: updatedAnnotation.rect,
                            contents: updatedAnnotation.contents,
                            author: updatedAnnotation.author,
                            color: {r : selectedColor[0], g : selectedColor[1], b : selectedColor[2]}
                        });
                    } else if (updatedAnnotation.type === '/Square') {
                        console.log('creating new square anot')
                        this.pdfFactory.createSquareAnnotation({
                            page: updatedAnnotation.page,
                            rect: updatedAnnotation.rect,
                            contents: updatedAnnotation.contents,
                            author: updatedAnnotation.author,
                            color: {r : selectedColor[0], g : selectedColor[1], b : selectedColor[2]}
                        });
                    }

                    var annotations = this.pdfFactory.getAnnotations()
                    annotations.then(value => {
                        this.annotations = []
                        for (var i in value) {
                            this.annotations.push([])
                            for (var annotation in value[i]) {
                                if (this.checkAnnotType((value[i][annotation].type)) && this.checkAnnotAuthor(value[i][annotation].author) && this.controlDeletedObjects(value[i][annotation].id, value[i][annotation].page)) {
                                    this.annotations[i].push(value[i][annotation])
                                }
                            }
                        }
                        this.loadAnnotGroups();
                    })
                })
                .catch(error => {
                    console.error('Error updating annotation:', error);
                });
        },
        controlDeletedObjects(id, page) {
            if (this.pdfFactory.toDelete) {
                for (var deleted of this.pdfFactory.toDelete) {
                    if (deleted.id === id && deleted.page == page) {
                        return false
                    }
                }
            }
            return true
        },
        checkAnnotAuthor(author) {
            if (author == "anonymize-text" || author == "anonymize-user-input" || author == "anonymize-square" || author == "anonymize-minex") {
                return true
            } else return false
        },
        checkAnnotType(annotType) {
            if (annotType == "/FreeText" || annotType == "/Highlight" || annotType == "/Square") {
                return true
            } else return false
        },
        refreshListOfAnnotations() {
            var annotations = this.pdfFactory.getAnnotations()
            annotations.then((value) => {
                this.annotations = []
                for (var page in value) {
                    let annots = []
                    for (var annotation in value[page]) {
                        if (this.checkAnnotType((value[page][annotation].type)) && this.checkAnnotAuthor(value[page][annotation].author) && this.controlDeletedObjects(value[page][annotation].id, value[page][annotation].page)) {
                            annots.push(value[page][annotation]);
                        }
                    }
                    this.annotations.push(annots)
                }
                this.loadAnnotGroups()
            })
        },
        setLabelForRecommendCategory(categoryId) {
            const obj = {
                'birth_id': "resultview.BirthNumber",
                'date': "downloadview.date",
                'email_address': "contactInformation.email",
                'iban': "bankInformation.iban",
                'postal_code': "apartmentInformation.postalcode",
                'phone_number': "contactInformation.telephonenumber",
                'streets': "apartmentInformation.street",
                'cities': "apartmentInformation.town",
                'price': 'suggesterLabelObject.sum',
                'names': 'suggesterLabelObject.name',
                'ico': 'suggesterLabelObject.ico',
                'dic': 'suggesterLabelObject.dic',
                'icdph': 'suggesterLabelObject.icdph',
                'variabilny_symbol': 'suggesterLabelObject.variableSymbol',
                'faktura': 'suggesterLabelObject.invoiceNumber'
            }
            return this.$t(obj[categoryId])
        },
        createMarkDiv(groupid){
            let div = document.createElement("div");

            div.className = "groupid"+groupid;
            div.style.visibility = "hidden";
            div.style.background = "rgba(191,197,71,0.4)"
            div.style.position = "absolute";
            div.style.zIndex = "0";
            return div;
        },
        unmarkAnnot(groupid){
            let markedAnnots = document.getElementsByClassName('groupid'+ groupid);
            while (markedAnnots.length > 0) markedAnnots[0].remove();
        },
        markAnnot(rect, page, groupid){
            let markDiv = this.createMarkDiv(groupid);

            // Find top-left and bottom-right coordinates
            let x0 = Math.min(rect[0], rect[2]);
            let y0 = Math.min(rect[1], rect[3]);
            let x1 = Math.max(rect[0], rect[2]);
            let y1 = Math.max(rect[1], rect[3]);

            markDiv.style.left = x0 + 'px';
            markDiv.style.top = y0 + 'px';
            markDiv.style.width = (x1 - x0) + 'px';
            markDiv.style.height = (y1 - y0) + 'px';
            markDiv.style.visibility = 'visible';

            let pageElement = document.getElementById('page'+ (page+1)).parentElement
            pageElement.appendChild(markDiv);
        },
        createHighlighterDiv(groupid){
            let div = document.createElement("div");
            div.className = "highlighterDiv"+groupid;
            div.style.visibility = "hidden";
            div.style.border = "2px dashed #ff0000";
            div.style.margin = "-2px"
            div.style.position = "absolute";
            div.style.zIndex = "5";
            return div;
        },
        highlightAnnot(rect, page,groupid){
            // Find top-left and bottom-right coordinates
            let x0 = Math.min(rect[0], rect[2]);
            let y0 = Math.min(rect[1], rect[3]);
            let x1 = Math.max(rect[0], rect[2]);
            let y1 = Math.max(rect[1], rect[3]);

            let highlighterDiv = this.createHighlighterDiv(groupid);
            let pageHeight = this.pdfPagesData[page].viewport.height
            let scale = this.pdfPagesData[page].viewport.scale
            x0 = x0 * scale
            y0 = pageHeight - (y0 * scale)
            x1 = x1 * scale
            y1 = pageHeight - (y1 * scale)

            highlighterDiv.style.left = x0 + 'px';
            highlighterDiv.style.top = y1 + 'px';
            highlighterDiv.style.width = (x1 - x0) + 'px';
            highlighterDiv.style.height = (y0 - y1) + 'px';
            highlighterDiv.style.visibility = 'visible';

            let pageElement = document.getElementById('page'+ (page+1)).parentElement
            pageElement.appendChild(highlighterDiv);
            highlighterDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
        },
        deleteAllHighlightedAnnots(){
            for (let i in this.annotGroups){
                this.deleteHighlightedAnnot(i);
            }
            for (let i in this.suggestedAnnotations){
                let groupid = parseInt(i) + this.suggestAnnotIndexOffset
                this.deleteHighlightedAnnot(groupid);
            }
            for (let page = 0; page < this.uniqueAnnotations.length; page++) {
                for (let annot = 0; annot < this.uniqueAnnotations[page].length; annot++) {
                    let annotation = this.uniqueAnnotations[page][annot]
                    this.deleteHighlightedAnnotListItem(annotation.author,page,annot)
                }
            }
        },
        deleteHighlightedAnnot(groupid){
            // Find all elements with the class name "highlighterDiv"
            var elements = document.getElementsByClassName("highlighterDiv"+groupid);

            // Convert the HTMLCollection to an array for easier manipulation
            var elementsArray = Array.from(elements);

            // Remove each element from the DOM
            elementsArray.forEach(function(element) {
                element.remove();
            });
        },
        deleteHighlightedAnnotListItem(author,page,index){
            // Remove highlight from annot list item
            let ref = 'annot_page' + page + '_index' + index
            let highlightClass = this.highlightedItemClass(author)
            this.$refs[ref][0].classList.remove(highlightClass);
        },
        isAnnotHighlighted(groupid){
            // Find all elements with the class name "highlighterDiv"
            var elements = document.getElementsByClassName("highlighterDiv"+groupid);

            // Convert the HTMLCollection to an array for easier manipulation
            var elementsArray = Array.from(elements);

            if (elementsArray.length > 0){
                return true;
            } else {
                return false;
            }
        },
        highlightAnnotation(groupid) {
            let annotPage = undefined
            let annotPageIndex = undefined
            let author = undefined

            let abort = false;
            for (let page = 0; page < this.uniqueAnnotations.length; page++) {
                for (let annot = 0; annot < this.uniqueAnnotations[page].length; annot++) {
                    if (this.uniqueAnnotations[page][annot].contents == groupid){
                        annotPage = page;
                        annotPageIndex = annot;
                        author = this.uniqueAnnotations[page][annot].author;
                        abort = true;
                        break;
                    }
                }
                if (abort) break;
            }

            if (this.isAnnotHighlighted(groupid)){
                this.deleteHighlightedAnnot(groupid);
                if (annotPage != undefined) {
                    this.deleteHighlightedAnnotListItem(author,annotPage,annotPageIndex);
                }
            } else {
                // Highlight pdf page annot
                for (let page=0; page < this.annotations.length; page++) {
                    for (let annot=0; annot < this.annotations[page].length; annot++) {

                        if (this.annotations[page][annot].contents == groupid) {
                            let annotation = this.annotations[page][annot]
                            let actualPage = annotation.page
                            this.highlightAnnot(annotation.rect, actualPage,groupid)
                        }
                    }
                }

                // Highlight annot list item
                if (annotPage != undefined) {
                    let ref = 'annot_page' + annotPage + '_index' + annotPageIndex
                    let highlightClass = this.highlightedItemClass(author)
                    this.$refs[ref][0].classList.add(highlightClass);
                    this.$refs[ref][0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }
        },
        highlightSuggested(coords, page, groupid) {
            groupid+=this.suggestAnnotIndexOffset
            if (this.isAnnotHighlighted(groupid)){
                this.deleteHighlightedAnnot(groupid);
            } else {
                let rect = []
                if (typeof (coords.x0) === 'undefined') {
                    coords.forEach(element => {
                        rect = this.calculateCoordinatesForAnnot(
                            [
                                element.x0,
                                element.y0,
                                element.x1,
                                element.y1
                            ], page)
                        }
                    );
                } else {
                    rect = this.calculateCoordinatesForAnnot(
                        [
                            coords.x0,
                            coords.y0,
                            coords.x1,
                            coords.y1
                        ], page)
                }

                let highlighterDiv = this.createHighlighterDiv(groupid);
                let x0 = rect[0]
                let y0 = rect[3]
                let x1 = rect[2]
                let y1 = rect[1]

                highlighterDiv.style.left = x0 + 'px';
                highlighterDiv.style.top = y1 + 'px';
                highlighterDiv.style.width = (x1 - x0) + 'px';
                highlighterDiv.style.height = (y0 - y1) + 'px';
                highlighterDiv.style.visibility = 'visible';

                let pageElement = document.getElementById('page'+ (page+1)).parentElement
                pageElement.appendChild(highlighterDiv);
                highlighterDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        },
        highlightClickedAnnot(e) {
            // Calculate viewPoint coordinates
            let ost = this.computePageOffset();
            let x = (e.clientX - ost.left);
            let y = (e.clientY - ost.top);

            // Calculate pagePoint coordinates
            let pagePoint = this.calculateCoordinatesForPageFromPoint(x, y);

            // Get current page
            let currentPage = this.lastCoordPage

            let x_y = this.pdfPagesData[currentPage].viewport.convertToPdfPoint(pagePoint[0], pagePoint[1])
            x = x_y[0]
            y = x_y[1]

            for (var n in this.annotations[currentPage]){
                let annot = this.annotations[currentPage][n]
                let boundaryRect = annot.rect
                let groupid = annot.contents

                // Check if (x, y) is within the annotation rectangle
                if (
                    x >= boundaryRect[0] &&
                    x <= boundaryRect[2] &&
                    y <= boundaryRect[1] &&
                    y >= boundaryRect[3]
                ) {
                     this.highlightAnnotation(groupid);
                     break;
                }
            }
        },
        deleteAnnotation(groupid) {
            // Uncheck if from suggested
            let keys = Object.keys(this.checkedAnnots)
            for (let index = 0; index < keys.length; index++) {
                let key = keys[index]
                if (this.checkedAnnots[key] == undefined) continue;
                if (this.checkedAnnots[key].id == groupid) {
                    delete this.checkedAnnots[key]
                    if (Object.keys(this.checkedAnnots).length > 0) {
                        this.checkAll = true
                    } else if (Object.keys(this.checkedAnnots).length == 0) {
                        this.checkAll = false
                    }
                }
            }

            if (this.isAnnotHighlighted(groupid)){
                this.deleteHighlightedAnnot(groupid);
            }

            this.unmarkAnnot(groupid);
            this.delAnnotGroup(groupid);

            // Create a deep copy of the annotations array
            const newAnnotations = this.annotations.map((pageAnnotations) =>
                pageAnnotations.map((annotation) => ({ ...annotation }))
            );

            for (let page = 0; page < this.annotations.length; page++) {
                for (let annot = 0; annot < this.annotations[page].length; annot++) {
                    if (this.annotations[page][annot].contents == groupid) {
                        let annotation = this.annotations[page][annot];
                        this.pdfFactory.deleteAnnotation(annotation.id, annotation.page);
                        this.removeFromHighlightList(annotation.rect, page)

                        // Update local annotations array by creating a new reference
                        newAnnotations[page] = newAnnotations[page].filter((a) => a.contents != groupid);
                    }
                }
            }

            // Update the original annotations array
            this.annotations = newAnnotations;
        },
        deleteAnnotations() {
            if (this.annotGroups.length > 0){
                let groupIDs = this.annotGroups
                for (let i in groupIDs) {
                    this.deleteAnnotation(groupIDs[i]);
                }
            }
        },
        calculateCoordinatesForHtml() {
            let rec = window.getSelection().getRangeAt(0).getBoundingClientRect()
            let ost = this.computePageOffset()
            let x_1 = (rec.x - ost.left)
            let y_1 = (rec.y - ost.top)
            let x_2 = (x_1 + rec.width)
            let y_2 = (y_1 + rec.height)
            return [x_1, y_1, x_2, y_2]
        },
        calculateCoordinatesForHtmlFromRect (rec) {
            let ost = this.computePageOffset()
            let x_1 = (rec.x - ost.left)
            let y_1 = (rec.y - ost.top)
            let x_2 = (x_1 + rec.width)
            let y_2 = (y_1 + rec.height)
            return [x_1, y_1, x_2, y_2]
        },
        calculateCoordinatesFromCustomRect(rect, actualPage) {
            const scale = this.pdfPagesData[actualPage].viewport.scale
            const scale2 = 1
            const maxX = this.pdfPagesData[actualPage].viewport.width
            var maxY = this.pdfPagesData[actualPage].viewport.height
            maxY = maxY * scale2
            
            let rec = rect
            let x_1 = (rec[0] * (scale2 * scale)) 
            let y_1 = maxY - (rec[1] * (scale2 * scale)) 
            let x_2 = (rec[2] * (scale2 * scale))
            let y_2 = maxY - (rec[3] * (scale2 * scale))
            return [x_1, y_1, x_2, y_2]
        },
        drawAnnotation(x1, x2, y1, y2, page) {
            let ctx = document.getElementById(page).getContext("2d")
            ctx.globalAlpha = 0.4
            ctx.fillStyle = this.color
            ctx.fillRect(
                x1,
                y1,
                (x2 - x1),
                (y2 - y1)
            )
            ctx.stroke()
        },
        computePageOffset() {
            let pageId = "page" + 1
            let pg = document.getElementById(pageId).parentElement

            var rect = pg.getBoundingClientRect(), bodyElt = document.body;

            return {
                top: rect.top + bodyElt.scrollTop,
                left: rect.left + bodyElt.scrollLeft,
                height: rect.height,
                width: rect.width
            }
        },
        clear() {
            this.coordinates = []
            this.htmlCoords = []
            this.htmlcoordinates = []
        },
        calcCrossPageRect(page,start,end,coords) {
            const scale = this.pdfPagesData[page].viewport.scale
            const scale2 = 1
            const widthDiff = (this.pagesPosition[start].width - this.pagesPosition[end].width) / (scale*scale2) / 2
            var coordsHTML = coords.map(x => x*scale*scale2)
            var viewPageHeight = this.pdfPagesData[page].viewport.height
            var pdfPageHeight = viewPageHeight/scale/scale2

            if (start == end) {
                var rectanglePDF = [
                        coords[0],
                        coords[1],
                        coords[2],
                        coords[3]
                ]
                var rectangleHTML = [
                    coordsHTML[0],
                    viewPageHeight - coordsHTML[1],
                    coordsHTML[2],
                    viewPageHeight - coordsHTML[3]
                ]
            }else {
                if (page == start) {
                    var rectanglePDF = [
                        coords[0],
                        coords[1],
                        coords[2] + widthDiff,
                        0
                    ]
                    var rectangleHTML = [
                        coordsHTML[0],
                        viewPageHeight - coordsHTML[1],
                        coordsHTML[2] + widthDiff*scale*scale2,
                        viewPageHeight
                    ]
                }
                if ((page != start) && (page != end)) {
                    var widthDiffStart = (this.pagesPosition[start].width - this.pagesPosition[page].width) / (scale*scale2) / 2
                    var widthDiffEnd = (this.pagesPosition[end].width - this.pagesPosition[page].width) / (scale*scale2) / 2

                    var rectanglePDF = [
                        coords[0] - widthDiffStart,
                        0,
                        coords[2] - widthDiffEnd,
                        pdfPageHeight
                    ]
                    var rectangleHTML = [
                        coordsHTML[0] - widthDiffStart*scale*scale2,
                        viewPageHeight,
                        coordsHTML[2] - widthDiffEnd*scale*scale2,
                        0
                    ]
                }
                if (page == end) {
                    var rectanglePDF = [
                        coords[0] - widthDiff,
                        pdfPageHeight,
                        coords[2],
                        coords[3]
                    ]
                    var rectangleHTML = [
                        coordsHTML[0] - widthDiff*scale*scale2,
                        0,
                        coordsHTML[2],
                        viewPageHeight - coordsHTML[3]
                    ]
                }
            }
            return [rectanglePDF, rectangleHTML]
        },
        processCoordinates(event) {
            let selectedColor = this.parseHexToRGB()

            // Calculate viewPoint coordinates
            let ost = this.computePageOffset();
            let x = (event.clientX - ost.left);
            let y = (event.clientY - ost.top);

            // Calculate pagePoint coordinates
            let pagePoint = this.calculateCoordinatesForPageFromPoint(x, y);

            // Get current page
            this.pageNumberCustomRect = this.lastCoordPage

            if (this.doSquare) {
                this.htmlcoordinates.push(pagePoint[0], pagePoint[1])
            }

            let x_y = this.pdfPagesData[this.pageNumberCustomRect].viewport.convertToPdfPoint(pagePoint[0], pagePoint[1])
            x = x_y[0]
            y = x_y[1]
            this.coordinates.push(x)
            this.coordinates.push(y)
            this.squarePages.push(this.lastCoordPage)

            if (this.doSquare) {
                if (this.coordinates.length == 4) {
                    let groupid = this.getNewAnnotGroup()

                    if (!(this.coordinates.slice()[0] == this.coordinates.slice()[2]) & !(this.coordinates.slice()[1] == this.coordinates.slice()[3])) {
                        let start = (this.squarePages[0] < this.squarePages[1]) ? this.squarePages[0] : this.squarePages[1]
                        let end = (this.squarePages[0] < this.squarePages[1]) ? this.squarePages[1] : this.squarePages[0]
                        let flipped = start != this.squarePages[0] ? true : false

                        if (flipped) {
                            var coords = [this.coordinates[2],this.coordinates[3],this.coordinates[0],this.coordinates[1]]
                        } else {
                            var coords = this.coordinates
                        }

                        for(let page = start; page <= end; page++){
                            var [rectanglePDF,rectangleHTML] = this.calcCrossPageRect(page,start,end,coords)

                            this.pdfFactory.createSquareAnnotation({
                                page: page,
                                rect: rectanglePDF,
                                contents: groupid.toString(),
                                author: 'anonymize-square',
                                color: {r : selectedColor[0], g : selectedColor[1], b : selectedColor[2]},
                            })
                            this.markAnnot(rectangleHTML, page, groupid)
                        }
                    } else {
                        alert("Anonymizačné políčko je príliš malé, bude ignorované. Na označenie väčšej plochy drž stlačené lavé myšítko a potiahni.")
                    }

                    this.coordinates = []
                    this.squarePages = []
                    this.htmlcoordinates = []
                    this.doSquare = false
                    this.addAnnotGroup(groupid)
                    this.refreshListOfAnnotations()
                    document.getElementById("viewer").style.userSelect = "auto"
                    document.getElementById("viewer").style.cursor = ""
                }
            }
        },
        calculateCoordinatesForPageFromPoint(x1,y1){
            let x,y = null;

            for(let i = 0; i < this.pagesPosition.length; i++){
                if((this.pagesPosition[i].y + this.pagesPosition[i].height) > y1){
                    this.lastCoordPage = i;
                    x = x1 + (this.pagesPosition[i].width - this.pagesPosition[0].width)/2;
                    y = y1 - this.pagesPosition[i].y;
                    return [x,y];
                }
            }
            return [x,y];
        },
        calculateCoordinatesForPageFromRect(rect){
            let ymax = Math.max(rect[3],rect[1]);
            let x1,x2,y1,y2 = null;

            for(let i = 0; i < this.pagesPosition.length; i++){
                if((this.pagesPosition[i].y + this.pagesPosition[i].height) > ymax){
                    this.lastCoordPage = i;
                    x1 = rect[0] + (this.pagesPosition[i].width - this.pagesPosition[0].width)/2;
                    x2 = rect[2] + (this.pagesPosition[i].width - this.pagesPosition[0].width)/2;
                    y1 = rect[1] - this.pagesPosition[i].y;
                    y2 = rect[3] - this.pagesPosition[i].y;
                    return [x1,y1,x2,y2]
                }
            }
            return [x1,y1,x2,y2];
        },
        loadAnnotGroups(){
            this.annotGroups = [];
            for (var page in this.annotations) {
                for (var annot in this.annotations[page]){
                    if (this.annotations[page][annot].author == 'anonymize-text' || this.annotations[page][annot].author == 'anonymize-square' || this.annotations[page][annot].author == 'anonymize-user-input' || this.annotations[page][annot].author == 'anonymize-minex'){
                        let groupId = this.getNewAnnotGroup()
                        if (this.annotations[page][annot].contents) {
                            groupId = parseInt(this.annotations[page][annot].contents)
                        }
                        else{
                            this.annotations[page][annot].contents = groupId.toString()
                        }
                        if (!this.annotGroups.includes(groupId)){
                            this.addAnnotGroup(groupId);
                        }
                    }
                }
            }
        },
        addAnnotGroup(groupid) {
            this.annotGroups.push(groupid)
        },
        delAnnotGroup(groupid){
            this.annotGroups = this.annotGroups.filter(function(v) { return v != groupid })
        },
        getNewAnnotGroup(suggested=false) {
            let newid = undefined
            if (suggested) {
                newid = this.suggestAnnotIndexOffset
                let groups = this.annotGroups.filter(id => id >= this.suggestAnnotIndexOffset)
                if (groups.length > 0){
                    newid = Math.max(...groups) + 1;
                }
            } else {
                newid = 0;
                let groups = this.annotGroups.filter(id => id < this.suggestAnnotIndexOffset)
                if (groups.length > 0){
                    newid = Math.max(...groups) + 1;
                }
            }
            return newid
        },
        addHighlightAnnotation() {
            let selectedColor = this.parseHexToRGB()
            let annotRect = []
            let viewRect = []
            let pageRect = []
            let groupid = this.getNewAnnotGroup()

            // Rectangles of selected text
            let rects = this.getSelectionBoxes()

            if (rects.length == 0 || (rects.length == 1 && (rects[0].width == 0 || rects[0].height == 0))){
                this.closeOverlay();
                return;
            }

            for (let i = 0; i < rects.length; i++) {
                // Convert html view coordinates to viewContainer coordinates
                viewRect = this.calculateCoordinatesForHtmlFromRect(rects[i])

                // Convert viewContainer coordinates to rendered page coordinates
                pageRect = this.calculateCoordinatesForPageFromRect(viewRect)

                // Get actual page of rectangle
                let actualPage = this.lastCoordPage

                // Check if selection is really in the PDF page
                let width = this.pdfPagesData[actualPage].viewport.width
                let height = this.pdfPagesData[actualPage].viewport.height
                
                if (pageRect[0] < 0 || pageRect[1] < 0 || pageRect[2] < 0 || pageRect[3] < 0) {
                    continue;
                }
                if (pageRect[0] > width || pageRect[1] > height || pageRect[2] > width || pageRect[3] > height) {
                    continue;
                }

                // Convert rendered page coordinates to PDF page coordinates
                annotRect = this.selectionCoordinates(pageRect[0],pageRect[1],pageRect[2],pageRect[3],actualPage)

                // Check if the selection isnt already annotated
                if (this.alreadyHighlighted(annotRect,actualPage)){
                    continue;
                }

                // Add the annotation to our annot list
                this.addToHighlightList(annotRect, actualPage);

                // Add the annotation to pdf
                this.pdfFactory.createHighlightAnnotation({
                    page: actualPage,
                    rect: annotRect,
                    contents: groupid.toString(),
                    author: 'anonymize-text',
                    color: {r : selectedColor[0], g : selectedColor[1], b : selectedColor[2]},
                    opacity: 0.5
                })

                // Draw the annotation
                this.markAnnot(pageRect, actualPage, groupid)
            }

            this.addAnnotGroup(groupid)
            this.coordinates = []
            this.refreshListOfAnnotations()
            this.doSquare = false
        },
        addSquareAnnotation() {
            if (!this.doSquare) {
                this.coordinates = []
                this.squarePages = []
                this.doSquare = true
                document.getElementById("viewer").style.cursor = "crosshair"
                document.getElementById("viewer").style.userSelect = "none"
            }
        },
        alreadyHighlighted(selRect, page){
            let alreadySelected = this.textSelAnnots
            if(alreadySelected.length === 0) return false;

            for (let i=0 ; i < alreadySelected.length; i++){
                let a = alreadySelected[i]
                if(a.page != page) continue;
                if(a.rectJSON === JSON.stringify(selRect)) return true;
            }
            return false;
        },
        addToHighlightList(rect, page){
            let annot = {
                'page' : page,
                'rectJSON' : JSON.stringify(rect)
            }
            this.textSelAnnots.push(annot);
        },
        removeFromHighlightList(rect, page){
            let alreadySelected = this.textSelAnnots
            if(alreadySelected.length === 0) {
                return;
            }

            for (let i=0 ; i < alreadySelected.length; i++){
                let a = alreadySelected[i]
                if(a.page != page) continue;
                if(a.rectJSON === JSON.stringify(rect)) {
                    // Remove the highlight from the highlights list
                    this.textSelAnnots.splice(i, 1);
                    break;
                }
            }
        },
        writeSuggestedAnnotsToPDF(pdffactory=null){
            if(!pdffactory){
                pdffactory = this.pdfFactory;
            }

            let color = this.parseHexToRGB()
            for (let key in this.checkedAnnots) {
                const scale = this.pdfPagesData[this.checkedAnnots[key].page].viewport.scale
                const scale2 = 1
                var maxY = this.pdfPagesData[this.checkedAnnots[key].page].viewport.height
                maxY = maxY / scale

                if ((typeof (this.checkedAnnots[key].newRect) !== 'undefined')) {

                    pdffactory.createHighlightAnnotation(
                        this.checkedAnnots[key].page,
                        [(this.checkedAnnots[key].newRect[0] / (scale2 * scale)),
                        (maxY - (this.checkedAnnots[key].newRect[1] / (scale2 * scale))),
                        (this.checkedAnnots[key].newRect[2] / (scale2 * scale)),
                        (maxY - (this.checkedAnnots[key].newRect[3] / (scale2 * scale)))],
                        this.checkedAnnots[key].id.toString(),
                        "anonymize-minex",
                        { r : color[0], g : color[1], b : color[2] }
                    )
                }
                if (typeof (this.checkedAnnots[key].newRects) !== 'undefined') {

                    for (let rect in this.checkedAnnots[key].newRects) {
                        pdffactory.createHighlightAnnotation(
                            this.checkedAnnots[key].page,
                            [(this.checkedAnnots[key].newRects[rect][0] / (scale2 * scale)),
                            (maxY - (this.checkedAnnots[key].newRects[rect][1] / (scale2 * scale))),
                            (this.checkedAnnots[key].newRects[rect][2] / (scale2 * scale)),
                            (maxY - (this.checkedAnnots[key].newRects[rect][3] / (scale2 * scale)))],
                            this.checkedAnnots[key].id.toString(),
                            "anonymize-minex",
                            { r : color[0], g : color[1], b : color[2] }
                        )
                    }
                }
            }

            return pdffactory
        },
        pushPdf() {
            this.isPushingPdf = true;
            this.showOverlay(this.$t("LoadingMessages.downloadLoading"));

            let data = this.pdfFactory.write()
            let formData = new FormData()
            let dataBlob = new Blob([data], { type: 'application/pdf' })
            formData.append('file', dataBlob)
            formData.append('fileName', this.fileId)
            
            setTimeout(() => {
                this.axios.post(
                    EnvProvider.value('API_URL') + this.$root.API_ANON,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            'x-csrf-token': this.cookies.get('csrfToken')
                        }
                    }
                ).then((resp) => {
                    if (resp.status === 200) {
                        this.closeOverlay();
                        this.$store.commit('pushMsg', { type: 'ok', text: 'Dokument bol úspešne zanonymizovaný' })
                        this.$router.push({
                            name: 'DownloadView'
                        })
                    } else {
                        this.$store.commit('pushMsg', { type: 'error', text: 'Dokument sa nepodarilo zanonymizovať' })
                    }
                })
            }, 500);
        },
        download() {
            this.pdfFactory.download(this.filename)
        },
    },
}
</script>

<style>
.activeButton {
    background-color: rgb(112, 200, 230);
}</style>

<style lang="scss" scoped src="@/assets/css/Pdfviewer.scss"/>
